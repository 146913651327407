import { apiClient } from "./api";


const serviceService = {


    async list(){

        var retorno = await apiClient.get('/service');

        return retorno.data;
    },

    async listActive(){

        var retorno = await apiClient.get('/service/active');

        return retorno.data;
    },

    async listServiceActive(idService){

        var retorno = await apiClient.get('/service/'+idService);

        return retorno.data;
    },
    
    
    async listQtdInsert(){

        var retorno = await apiClient.get('/qtdinsert');

        return retorno.data;
    },

    async listFilter(id){
        console.log(id)

        var retorno = await apiClient.get('/service/filter/'+id);

        return retorno.data;
    },


    async edit(id){

        var retorno = await apiClient.get("/service/"+id);
        
        return retorno.data;
    },

    async save(customer){
        console.log(customer)

        var retorno = await apiClient.post("/service", customer);

        return retorno;

    },

    async update(customer){
        console.log(customer)

        var retorno = await apiClient.put("/service", customer);

        return retorno;

    },

    async delete(idcustomer){
        console.log(idcustomer)

        var retorno = await apiClient.delete("/service/"+idcustomer);

        return retorno;

    }

}

export default serviceService;