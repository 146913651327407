import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Button, Result } from 'antd';

export default function ResponsiveDialog({open, handleClose, title, message, status}) {

const theme = useTheme();
const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

return (
  <div>
    <Dialog
      maxWidth={"sm"}
      fullWidth={true}
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      aria-describedby="responsive-dialog-describe"
    >
      <div style={{ padding: '20px', textAlign: 'center' }}>
        <Result
          status={status}
          title={<h2 style={{ marginBottom: '10px', color: '#333' }}>{title}</h2>}
          subTitle={<p style={{ color: '#6F89A4' }}>{message}</p>}
          style={{ marginBottom: '10px' }}
        />
        <div style={{ textAlign: 'center' }}>
          <Button
            size="large"
            style={{
              background: '#000',
              color: '#FFF',
              width: '50%',
              marginTop: '10px',
            }}
            onClick={handleClose}
            autoFocus
          >
            OK, entendi
          </Button>
        </div>
      </div>
    </Dialog>
  </div>
);

  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

  // return (
  //   <div>

  //     <Dialog
  //         maxWidth={"sm"}
  //         fullWidth={true}        
  //         fullScreen={fullScreen}
  //         open={open}
  //         onClose={handleClose}
  //         aria-labelledby="responsive-dialog-title"
  //         aria-describedby='resposive-dialog-describe'
  //       >
  //         <Result
  //             status={status}
  //             title={title}
  //             subTitle={message}
  //             extra={[
  //               <Button size='large' style={{background: '#000', color: '#FFF', width: '50%' }}
  //                 onClick={handleClose} autoFocus
  //                 >OK, entendi
  //               </Button>
  //             ]}
  //           />


  //       </Dialog>

  //   </div>
  // );
}