import React from 'react';
import { useDrop } from 'react-dnd';
import Task from './tasks';

const Column = ({ idcolumn, name, tasks = [], moveTask, vltotalColumn, onDelete, onCloseSalve }) => {

  // Configura o comportamento de drop da coluna
  const [, drop] = useDrop({
    accept: 'task',
    drop: (item) => {
      moveTask(item.id, item.sourceColumnId, idcolumn);
    },
  });


  return (
    <div
      ref={drop}
      style={{
        margin: 6,
        padding: 16,
        backgroundColor: '#f0f0f0',
        borderRadius: 4,
        width: '290px', // Largura fixa da coluna
        minWidth: '290px', // Evita encolher
        maxWidth: '290px', // Evita crescer
        height: '700px', // Altura fixa
        boxShadow: '0 0 5px rgba(0,0,0,0.15)',
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0, // Impede que o flexbox ajuste o tamanho
      }}
    >
      {/* Cabeçalho com título, quantidade e valor */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 2, // Espaço entre o cabeçalho e as tarefas
        }}
      >
        {/* Título */}
        <div
          className="card-title"
            style={{
              fontWeight: 'bold',
              fontSize: '1.1rem',
              color: '#333',
              marginBottom: 2, // Espaço entre título e a linha abaixo
            }}
        >
          {name}
        </div>

        {/* Linha com Quantidade e Valor */}
        <div
            style={{
              width: '100%',
              padding: '8px 0',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              fontSize: '0.9rem',
              color: '#555',
              boxSizing: 'border-box',
            }}
          >
            <div style={{ marginLeft: '10px' }}>Quantidade: {tasks.length}</div>
              <div style={{ color: '#0047ab', fontWeight: 'bold', marginRight: '10px' }}>
                {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(vltotalColumn)}
              </div>
          </div>
      </div>

      {/* Contêiner rolável para as tarefas */}
      <div
          style={{
            flex: 1,
            overflowY: 'auto', // Habilita rolagem vertical
            maxHeight: '500px', // Define uma altura máxima para a área das tarefas
            marginTop: 8,
            transition: 'all 0.2s ease-in-out', 
          }}
        >
        {tasks.length > 0 ? (
            tasks.map((task, index) => (
              <Task
                key={`${task.idbudget}-${index}`}
                id={task.idbudget}
                title={task.campanha}
                task={task}
                sourceColumnId={idcolumn}
                onDelete={() => onDelete(task.idbudget, idcolumn)}
                onCloseSalve={onCloseSalve}
              />
            ))
          ) : (
            <span style={{ fontSize: '15px' }}>Ainda não há orçamento associado a esta coluna</span>
          )}
      </div>
    </div>

  

  );
};

export default Column;
