import React, { useState } from "react";
import { Container} from "@mui/material";
import DrawerPrincipal from "../../component/drawer/drawer-principal";
import MainKamban from "../../component/kamban/main";
import CustomizedBreadcrumbs from "../../component/breadcrumbs/breadcrumbs";


function ListBudget(){
    // const [isModalVisible, setIsModalVisible] = useState(false);


    // const handleOk = () => {
    //     console.log("SALVANDO NOVO ")
    //     setIsModalVisible(true); // Fecha o modal ao clicar em "OK"
        
    // };

    // const handleCancel = () => {
    //     setIsModalVisible(false); // Fecha o modal ao clicar em "Cancelar"
    //     console.log("CANCELANDO NOVO  ")
    // };
    
  
    return(

        <>

        <Container maxWidth="lx" >
            <DrawerPrincipal
                title={"Orçamento"}
                divOpen={
                <div>
                    <div>
                    <div className="row mb-3">
                        <CustomizedBreadcrumbs title1={"Home"} link1={"/"} title2={"Lista de Orçamento"} link2={"#"} />
                    </div>
                    

                {/* <Paper
                    sx={{
                        width: '95vw', // Largura responsiva padrão
                        maxWidth: 1450, // Limite máximo de largura para telas médias
                        height: 'calc(100vh - 70px)', // Altura responsiva ajustada ao tamanho da tela menos a altura do AppBar
                        margin: '0 auto', // Centraliza horizontalmente
                        overflow: 'hidden', // Esconde conteúdo excedente
                        display: 'flex', // Define layout flexível
                        flexDirection: 'column', // Organiza o conteúdo verticalmente
                        boxShadow: 3, // Sombra para o efeito de elevação
                        overflowY: 'auto', // Permite rolagem vertical
                        '@media (max-width: 600px)': { // Ajuste para telas pequenas
                        width: '100%', // Largura total em telas menores
                        padding: '10px',
                        maxWidth: 1000,
                        overflow: 'hidden',
                        },
                        '@media (min-width: 600px) and (max-width: 1680px)': { // Ajuste para telas médias até 1680px
                        width: '100%',
                        maxWidth: 1100, // Define um limite para largura específica nessa resolução
                        height: '80vh',
                        overflow: 'hidden',
                        },
                        '@media (min-width: 900px) and (max-width: 1440px)': {
                            width: '100%', // Garante 100% da largura da tela para essa resolução
                            maxWidth: 1280, // Remove espaçamento interno
                        },
                        '@media (min-width: 1680px) and (max-width: 1920px)': { // Ajuste para telas médias maiores
                        width: '100%', // Ajusta a largura para criar margens equilibradas
                        maxWidth: 1500, // Limita a largura máxima
                        overflow: 'hidden',
                        },
                        '@media (min-width: 1920px)': { // Ajuste para telas grandes (monitores de 27 polegadas ou mais)
                        width: '100%', // Reduz a largura para criar margens maiores em telas muito largas
                        maxWidth: 1750, // Limita a largura máxima para evitar esticar muito
                        overflow: 'hidden',
                        },
                    }}
                >

                <AppBar
                    position="relative" // Posição relativa
                    color="default"
                    elevation={0}
                    sx={{
                        width: '100%',
                        height: '70px', // Altura fixa para o AppBar
                        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                    }}
                >
                    <Toolbar>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item style={{ width: 200 }}>
                                <h5>Lista de Orçamento</h5>
                            </Grid>
                            <Grid item xs />
                            <Grid item>
                                <Button
                                    onClick={handleOk}
                                    style={{ background: 'black', color: '#FFF' }}
                                    sx={{ mr: 1 }}
                                >
                                    <AddIcon /> Novo
                                </Button>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar> */}

                <div style={{ width: '100%', padding: 1 }}>
                    <MainKamban />
                </div>
                {/* </Paper> */}




                    {/* Conteúdo principal diretamente abaixo do AppBar */}
                    {/* <div style={{ width: '100%', padding: 10 }}>
                        <MainKamban />
                    </div> */}
                

                    {/* <Modal
                        title={"Orçamento"}
                        visible={isModalVisible}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        footer={null} // Remove o rodapé
                        centered // Centraliza o modal na tela
                        style={{ top: '5vh' }} // Centraliza verticalmente com base na viewport
                        bodyStyle={{ 
                        height: '80vh', // Define a altura como 90% da altura da viewport
                        padding: 0, 
                        margin: 0, 
                        overflow: 'auto' // Permite rolagem se o conteúdo ultrapassar o modal
                        }}
                        width="90vw" // Define a largura como 90% da largura da viewport
                        closable={true} // Remove o botão de fechar
                        maskStyle={{ 
                        backgroundColor: 'rgba(0, 0, 0, 0.5)' // Altera a opacidade do fundo
                        }}
                    >
                        <div>
                        <NewAvailability onCloseSave={handleOk}/>
                        </div>
                    </Modal> */}

                </div>
            </div>
            }
        />
            </Container>

        </>

    )



}


export default ListBudget;