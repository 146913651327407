import axios from "axios";

export const apiClient = axios.create({
    // baseURL: 'http://crm.trendmidia.com.br:3000/api'
    // baseURL: 'http://localhost:3000/api'
    baseURL: 'https://trend-midia.uc.r.appspot.com/api'
    // baseURL: 'http://api-node-trendmidia.us-east-1.elasticbeanstalk.com/api'
});


export const createSession = async (user) => { 
    return await apiClient.post('/login', {login: user.login, password: user.password});
};


// apiClient.interceptors.request.use(async config => {
//     const token = user.token
//     console.log("Intercepta a requisição")
//     if(token){
//         config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;

// })
