import { apiClient } from "./api";


const panelService = {


    async list(){

        var retorno = await apiClient.get('/panel');

        return retorno.data;
    },

    async listActive(){

        var retorno = await apiClient.get('/panel/active');

        return retorno.data;
    },

    async edit(id){

        var retorno = await apiClient.get("/panel/"+id);
        return retorno.data;
    },

    async listInsertsByQtd(qtd){

        var retorno = await apiClient.get("/qtdinsert/"+qtd);
        return retorno.data;
    },

    async listPanelFilter(name){

        var retorno = await apiClient.get('/panel/filter/'+name);

        return retorno.data;
    },

    async save(customer){

        var retorno = await apiClient.post("/panel", customer);

        return retorno;

    },

    async update(customer){

        var retorno = await apiClient.put("/panel", customer);

        return retorno;

    },

    async delete(idcustomer){

        var retorno = await apiClient.delete("/panel/"+idcustomer);

        return retorno;

    }

}

export default panelService;