import React from 'react';
import { Select } from 'antd';
import { Option } from 'antd/es/mentions';

const options = [];

for (let i = 10; i < 36; i++) {
  options.push({
    value: i.toString(36) + i,
    label: i.toString(36) + i,
  });
}
export default function SelectedTag({data, onChange, selectedPanels}) {

    return (
        <Select
            mode="tags"
            style={{
            width: '100%',
            }}
            size={"large"}
            placeholder="Painel"
            // value={selectedPanels.map(panel => panel?.idpanel)}
            value={selectedPanels}
            onChange={onChange}
            // options={data}
            
        >
            {data.map((option) => (
                <Option key={option.idpanel} value={option.idpanel}>
                    {option.name}
                </Option>
            ))}
        </Select>

    );

// const SelectedTag = ({data, onChange, selectedPanels}) => (

    // console.log(data)
    // console.log(selectedPanels)

    // selectedPanels
        // <Select
        //     mode="tags"
        //     style={{
        //         width: '100%',
        //     }}
        //     size="large"
        //     placeholder="Painel"
        //     // value={selectedPanels}
        //     value={selectedPanels ? selectedPanels.map(panel => panel?.idpanel) : ''} // Controlado pelo estado
        //     onChange={onChange}
        //     >
        //     {data.map((option) => (
        //         <Option key={option.idpanel} value={option.idpanel}>
        //         {option.name}
        //         </Option>
        //     ))}
        // </Select>
       
    }

