import React, { useEffect, useState } from "react";
import { Row, Col, Button, Radio, Typography,  Layout, Input, Tooltip, Skeleton } from 'antd';
import ResponsiveDialog from "../../component/dialog/dialog";
import panelService from "../../service/servicePanel";
import serviceService from "../../service/serviceService";
import DateRangePicker from "../../component/datapicker/data-ranger-picker";
import availabilityService from "../../service/serviceAvailable";
import ImgMediaCard from "../../component/card-visible/card-visible";
import SelectedTag from "../../component/select-tag/selecttag";
import dayjs from "dayjs";
import moment from "moment";
import MyComponent from "../../component/input-mask/input-mask";
import { useNavigate } from "react-router-dom";
import customerService from "../../service/serviceCustomer";
import { Autocomplete, Container, InputAdornment, TextField } from "@mui/material";
import './availability.css'
import { PlusOutlined } from "@ant-design/icons";
import userService from "../../service/serviceUser";
const { Content, Footer } = Layout;

function NewAvailability({idbudget, onClose}){

    const navigate = useNavigate();

    const usuario = JSON.parse(localStorage.getItem('user_token'));

    const [service, setService] = useState("");
    const [panel, setPanel] = useState([]);
    const [loading, setLoading] = useState(false);

    const [listPanel, setListPanel] = useState([]);
    const [listService, setListService] = useState([]);
    const [dtIni, setDtIni] = useState(dayjs(dayjs(), 'YYYY-MM-DD'));
    const [dtFim, setDtFim] = useState(dayjs(dayjs(), 'YYYY-MM-DD'));
    const [campanha, setCampanha] = useState("");
    const [idClient, setIdClient] = useState();
    const [customer, setCustomer] = useState();
    const [listCustomer, setListCustomer] = useState([]);
    const [servicePanel, setServicePanel] = useState([]); 
    const [user, setUser] = useState(usuario);
    const [listUser, setListUser] = useState([]);

    const [days, setDays] = useState(0);
    const [vlService, setVlService] = useState(0);
    const [qtdInsercao, setQtdInsercao] = useState();

    const [itensDaVenda, setItensDaVenda] = useState("0,00");
    const [desconto, setDesconto] = useState("0,00");
    const [total, setTotal] = useState("0,00");
    const [idService, setIdService] = useState();
    const [totalService, setTotalService] = useState(0);


    const [openDialog, setOpenDialog] = useState();
    const [messageDialog, setMessageDialog] = useState();
    const [titleDialog, setTitleDialog] = useState();
    const [statusDialog, setStatusDialog] = useState();


    const [listInserts, setListInserts] = useState([]);


    const [typeCalc, setTypeCalc] = useState('value');
    const [listaIDCustomer, setListaIDCustomer] = useState([]);
    const [listaIDService, setListaIDService] = useState();
    const [listaIdQtdInsert, setListaIdQtdInsert] = useState([]);


    const config = true ? {style: 'currency', currency: 'BRL'} : {minimumFractionDigits: 2};

    const { Text } = Typography;
    

    const style = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        minHeight: '80vh',
        margin: 0,
        padding: 0,
        boxSizing: 'border-box'
      };

    useEffect(() => {

        (async ()=> {

            setLoading(true);


            await panelService.listActive().then(item => {
                var dadoPanel = [];

                item.forEach(element => {
                    dadoPanel.push(element)    
                });

                setListPanel(dadoPanel);
            });


            await serviceService.listActive().then(item => {
                var dadoService = [];
                item.forEach(element=> {
                    dadoService.push(element);
                });

                setListService(dadoService);
            })


            var listQtdInserts = [];
            serviceService.listQtdInsert().then(item => {
    
                item.forEach(element => {
                    listQtdInserts.push(element);
                });
    
                setListInserts(listQtdInserts)
                
            }).catch(error => {
                console.log(error)
            });

            var listCust = [];
            customerService.listCustomerActive().then(item => { 
                
                item.forEach(element => {
                    listCust.push(element);
                });
    
                setListCustomer(listCust)
                
            }).catch(error => {
                console.log(error)
            });

            userService.editUser(usuario.iduser).then(item=> {                        
                setUser(item);
            });

            var listUser = [];
            userService.listarUser().then(item => {
    
                item.forEach(element => {
                    listUser.push(element);
                });
    
                setListUser(listUser)
                
            }).catch(error => {
                console.log(error)
            });

            if(idbudget){
                availabilityService.findBudget(idbudget).then(async budget => {
                    
                    const task = budget.tasks;

                    setCampanha(task.campanha)
                    setIdClient(task.customer)
                    setDesconto(task.discount)
                    setTypeCalc(task.typecalc)
                    

                    setTotal(task.ticket)
                    setQtdInsercao(task.qtdinsert)
                    setIdService(task.service)
                    setDtIni(task.dtini)
                    setDtFim(task.dtend)
                    
                    var listaIdPanel = [];
                    task.panels.forEach(item=> {
                        listaIdPanel.push(item.idpanel)
                    })

                    setPanel(listaIdPanel)
                    
                    if(task.customer){ 
                        customerService.editCustomer(task.customer).then(item=> {
                            setCustomer(item)
                            setListaIDCustomer(item);
                        });
                    }
    
                    var vlSaleService = 0;

                    await serviceService.listServiceActive(task.service).then(item=> {
                        setVlService(item.vlSale)
                        vlSaleService = item.vlSale;
                        setListaIDService(item);
                        setServicePanel(item)
                        setService(item)
                    });
    
     
                    // panelService.listInsertsByQtd(task.qtdinsert).then(item=> {
                    //     setListaIdQtdInsert(item);
                    // });

                    var ini = moment(task.dtini, 'YYYY-MM-DD');
                    var end = moment(task.dtend, 'YYYY-MM-DD');

                    //Calculando a diferença entre as duas datas somando mais 1 para dar o dia cheio
                    var diff = dayjs(end).diff(dayjs(ini));
                    var diffDays = (moment.duration(diff).asDays() + 1);

                    setDays(diffDays);
                    

                    calculateTotal(task.items, task.qtdinsert, diffDays, vlSaleService, task.discount, task.ticket);
                    
                    setPanelFields(task.items);
    
                    
                }).catch(error => {
                    console.error(error)
                }).finally(item=> {
                    setLoading(false);
                })
            }

            setLoading(false)
            
        })()

    },[]);


    const handleDialogClose = () => {
        setOpenDialog(false);
        onClose();
        // navigate(0);
    };


    const calculateDay = (dtStart, dtEnd) => {

         //Calculando a diferença entre as duas datas somando mais 1 para dar o dia cheio
         var diff = dayjs(dtEnd, 'DD/MM/YYYY').diff(dayjs(dtStart, 'DD/MM/YYYY'));
         var diffDays = (moment.duration(diff).asDays() + 1);
 
         setDays(diffDays);

        // console.log(diffDays)

        //  calculateTotal(qtdInsercao, diffDays, vlService)
    }

    const handleChangeDays = (dates, dateStrings) => {
        console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
         
        // setDtInicio(moment(dateStrings[0], 'DD/MM/YYYY').format('YYYY-MM-DD'))
        // setDtFinal(moment(dateStrings[1], 'DD/MM/YYYY').format('YYYY-MM-DD'))

        setDtIni(moment(dateStrings[0], 'DD/MM/YYYY').format('YYYY-MM-DD'));
        setDtFim(moment(dateStrings[1], 'DD/MM/YYYY').format('YYYY-MM-DD'));

        calculateDay(dateStrings[0], dateStrings[1]);        
    }

    const handleChangePanel = (value) => {
        
        setPanel(value)
    } 

    const handleChangeService = (event) => {

        setVlService(event.vlSale)
        setListaIDService(event)

    }

    const handleChangeQtdService = (index, qtdInsert) => {
        setQtdInsercao(qtdInsert)
    }

    const totalComDesconto = (desconto, type) => {

        var totalCampanha = panelFields.reduce((a, b) => parseFloat(a) + parseFloat(b.vlTotal), 0)

        var totalGeral = totalCampanha
        
        if(type == 'percent') {
            totalGeral = totalGeral - (parseFloat(totalGeral) * parseFloat(desconto) / 100);
        }else if(type == 'value'){
            totalGeral = totalGeral - parseFloat(desconto);
        }

        setTotal(totalGeral)
    }
    
    const calculateDiscount = (total, discount, isChecked) => {


        var totalGeral = 0;

        if(isChecked){
            totalGeral = total - parseFloat(discount);
        }else{
            totalGeral = total + parseFloat(discount);
        }

        if(typeCalc == 'percent') {
            totalGeral = (totalGeral * parseFloat(desconto) / 100)
        }else{
            totalGeral = totalGeral - parseFloat(desconto);
        }

    }

    const calculateChecked = (isChecked, vlService) => {
        var totalGeral = 0;
        var valorCalc = 0;

        if(isChecked){
            valorCalc = ((parseFloat(vlService) * parseInt(qtdInsercao) * parseInt(days)));
            totalGeral = (parseFloat(total) + valorCalc)
        }else{
            valorCalc = ((parseFloat(vlService) * parseInt(qtdInsercao) * parseInt(days)));
            totalGeral = (parseFloat(total) - valorCalc)
        }

        calculateDiscount(totalGeral, desconto, isChecked);

        setTotal(totalGeral);
    }


    const calculateTotal = async (item, qtdInsert, qtdDays, vlService, discount, valorTotal) => {

        
        var vltotal = (parseFloat(vlService) * parseInt(qtdInsert) * parseInt(qtdDays)) ;
        
        var filtrado = item.filter((item) => item.isChecked == true);

        var geral = (vltotal * filtrado.length) - (desconto && desconto != '0,00' ? parseFloat(desconto) : parseFloat(discount))

        setItensDaVenda((vltotal * filtrado.length))
        setTotal(geral)
        setTotalService(vltotal);
    }

    const handleFind = async () => {
        setLoading(true)
        var listPanel = [];

        await availabilityService.findItemsByPanel(panel, dtIni, dtFim, qtdInsercao).then(async item => {
            item.forEach(disp => {
                disp.isChecked = true;

                disp.service = listaIDService;

                var ini = moment(dtIni, 'YYYY-MM-DD');
                var end = moment(dtFim, 'YYYY-MM-DD');

                var diff = dayjs(end).diff(dayjs(ini));
                var diffDays = (moment.duration(diff).asDays() + 1);

                var vltotal = (parseFloat(vlService) * parseInt(qtdInsercao) * parseInt(diffDays));

                disp.vlTotal = vltotal;

                listPanel.push(disp);
            });
            
            calculateTotal(listPanel, qtdInsercao, days, vlService, desconto, total);

            setPanelFields(listPanel);
            

        }).catch(erro => {
            console.erro(erro)
        })
        .finally(() => {
                setLoading(false)
        });



    }
    

    const handleChangeServicePanel = (e, index) => {
        let data = [...panelFields];

        var ini = moment(dtIni, 'YYYY-MM-DD');
        var end = moment(dtFim, 'YYYY-MM-DD');

        //Calculando a diferença entre as duas datas somando mais 1 para dar o dia cheio
        var diff = dayjs(end).diff(dayjs(ini));
        var diffDays = (moment.duration(diff).asDays() + 1);

        data[index].service = e;

        var totalItem = (parseFloat(data[index].service.vlSale) * parseFloat(data[index].qtdInsert) * parseInt(diffDays));

        data[index].vlTotal = totalItem;
        
        setPanelFields(data);
        //calculando o total 

        calculateTotalGeral();

        // var totalCampanha = panelFields.reduce((a, b) => parseFloat(a) + parseFloat(b.vlTotal), 0)

        // console.log(totalCampanha)

        // //Calculando o desconto 

        // var totalGeral = (parseFloat(totalCampanha) );
        // setItensDaVenda(totalCampanha)
        
        // if(typeCalc == 'percent') {
        //     totalGeral = totalGeral - (parseFloat(totalGeral) * parseFloat(desconto) / 100);
        // }else if(typeCalc == 'value'){
        //     totalGeral = totalGeral - parseFloat(desconto);
        // }

        // setTotal(totalGeral)

        
    };


    const calculateTotalGeral = () => {

        var totalCampanha = panelFields.reduce((a, b) => parseFloat(a) + parseFloat(b.vlTotal), 0)

        //Calculando o desconto 
        var totalGeral = (parseFloat(totalCampanha) );
        setItensDaVenda(totalCampanha)
        
        if(typeCalc == 'percent') {
            totalGeral = totalGeral - (parseFloat(totalGeral) * parseFloat(desconto) / 100);
        }else if(typeCalc == 'value'){
            totalGeral = totalGeral - parseFloat(desconto);
        }

        setTotal(totalGeral)

    }

    const handleChangeServiceQtdInsert = (event, index) => {
        let data = [...panelFields];
        
        data[index].qtdInsert = event;

        var ini = moment(dtIni, 'YYYY-MM-DD');
        var end = moment(dtFim, 'YYYY-MM-DD');

        //Calculando a diferença entre as duas datas somando mais 1 para dar o dia cheio
        var diff = dayjs(end).diff(dayjs(ini));
        var diffDays = (moment.duration(diff).asDays() + 1);

        var totalItem = (parseFloat(data[index].service.vlSale) * parseFloat(data[index].qtdInsert) * parseInt(diffDays));

        data[index].vlTotal = totalItem;

        setPanelFields(data);

        calculateTotalGeral();

    }

    const onChangeCheckbox = (event, index, item) => {
        
        let data = [...panelFields];

        data[index]['isChecked'] = event;

        setPanelFields(data);

        calculateChecked(event, vlService);

    }

    const preperData = () => {
        let data = [...panelFields];

        var list = [];

        data.forEach(item=> {
            // if(item.isChecked){
                list.push(item.panel);
            // }

        })


        var prepareData = { 
            list: list,
            qtd_insercao: qtdInsercao,
            days: days,
            dtIni: moment(dtIni, 'YYYY-MM-DD').format('DD/MM/YYYY'),
            dtEnd: moment(dtFim, 'YYYY-MM-DD').format('DD/MM/YYYY'),
            service: service,
            discount: desconto == "0,00" ? 0 : desconto,
            customer: customer,
            campanha: campanha,
            idbudget: idbudget
        }
        
        // Navegar para a próxima tela passando os parâmetros
        navigate('/saleParams/', { state: {prepareData} });

        return list;

    }

    const handleChangeUser = (input, newInput) => {
        setUser(input)
    }

    const handleChangeClient = (input, newInput) => {
        setIdClient(input.idcustomer)
        setCustomer(input)
        setListaIDCustomer(input)
    }

    const editarBuget = () => {
        // let data = [...panelFields];

        var listPanels = [];
        var listItems = [];

        panelFields.forEach(item=> {
            listPanels.push(item.panel);
            listItems.push(item)
        })
   

        var pData = { 
            idbudget: idbudget,
            items: listItems,
            panels: listPanels,
            qtdinsert: qtdInsercao,
            days: days,
            dtini: dtIni,
            dtend: dtFim,
            service: listaIDService,
            discount: desconto == "0,00" ? 0 : desconto,
            typecalc: typeCalc,
            ticket: total,
            iduser: user.iduser,
            campanha: campanha,
            customer: customer,
            idsaller: user.iduser

        }
    
        console.log(pData)

        availabilityService.editarBuget(pData).then(item => {

            if(item.status === 201){ 
                setOpenDialog(true);
                setStatusDialog('success')
                setTitleDialog("Muito bem!")
                setMessageDialog("Orçamento editado com sucesso")
            }
        }).catch(error => {
            console.log(error.response.data)
            setOpenDialog(true)
            setStatusDialog('error')
            setTitleDialog("Ops")
            setMessageDialog(error.response.data)
        });

    }


    const createBuget = () => {
        let data = [...panelFields];

        var listPanels = [];
        var listItems = [];

        data.forEach(item=> {
            listPanels.push(item.panel);
            listItems.push(item)
        })

        var pData = { 
            items: listItems,
            panels: listPanels,
            qtdinsert: qtdInsercao,
            days: days,
            dtini: dtIni,
            dtend: dtFim,
            service: listaIDService,
            discount: desconto == "0,00" ? 0 : desconto,
            typecalc: typeCalc,
            ticket: total,
            iduser: user.iduser ? user.iduser : usuario.iduser,
            campanha: campanha,
            customer: customer,
            idsaller: usuario.iduser

        }

        console.log(pData)

        availabilityService.createBuget(pData).then(item => {

            if(item.status === 200){ 
                setOpenDialog(true);
                setStatusDialog('success')
                setTitleDialog("Muito bem!")
                setMessageDialog("Orçamento criado com sucesso")
            }
        }).catch(error => {
            console.log(error.response.data)
            setOpenDialog(true)
            setStatusDialog('error')
            setTitleDialog("Ops")
            setMessageDialog(error.response.data)
        });

    }

    const [panelFields, setPanelFields] = useState(
        [{
            idItem: 0,
            qtdService: 0,
            dtStart: "",
            dtEnd: "",
            vlUnit: 0,
            idpanel: 0,
            idservice: 0,
            vlTotal: 0,
            totalInsert: 0,
            qtdInsert: "0",
            vlLiqPercMed: 0,
            percentLiquid: 0,
            vlTotalPercMed: 0,
            percentMedio: 0,
            isChecked: true,
            period: [],
            panel: {
                idpanel: 0,
                name: "",
                type: "",
                dtinsert: "",
                dtupdate: null,
                valid: true,
                address: {
                    idAddress: 0,
                    address: "",
                    number: 0,
                    neighborhood: "",
                    city: "",
                    complement: "N",
                    state: "",
                    country: "",
                    cep: ""
                },
                availability: []
            },
            service: {
                idservice: 0,
                name: "",
                type: "",
                qtdInsert: 0,
                vlSale: 0,
                user: "",
                codExterno: "",
                isActive: false,
                dtinsert: "",
                dtupdate: "",
                isIntegrate: false
            }
        }]
    );



    const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
        PaperProps: {
            style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            },
        },
    };


    return(

    <>

    <Container maxWidth="lx" sx={{ height: '100vh' }}>
        
        {/* <DrawerPrincipal divOpen={ */}
            <div>
                <div>
                {/* {
                    idbudget ? 
                    ""
                    :
                    <div className="row mb-1">
                            <CustomizedBreadcrumbs title1={"Home"} link1={"/"} 
                                    title2={"Orçamento"} link2={"#"} 
                                    title3={""} link3={"#"}/>
                    </div>
                } */}
                <div style={style}>
                
                        <ResponsiveDialog 
                            open={openDialog} 
                            handleClose={handleDialogClose}
                            title={titleDialog}
                            message={messageDialog} 
                            status={statusDialog}
                            />


                            <Layout style={{background: '#FFF'}}>
                                <Layout  style={{background: '#FFF'}}>
                                    {/* <Header
                                        style={{
                                            padding: 0,
                                            // background: colorBgContainer,
                                        }}
                                    /> */}
                                    <Content
                                        // style={{
                                        //     margin: '24px 16px 0',
                                        // }}
                                    >
                                    <div
                                        style={{
                                        // padding: 24,
                                        minHeight: 360,
                                        // background: colorBgContainer,
                                        // borderRadius: borderRadiusLG,
                                        }}>
                                        <Row gutter={[16, 16]}>
                                            <Col span={8} > 
                                                <Autocomplete
                                                    size='small'
                                                    id="combo-box-demo"
                                                    value={user && user}
                                                    sx={{ width: '100%' }}
                                                    getOptionLabel={(listUser) => listUser ? `${listUser.name}` : ""}
                                                    options={listUser}
                                                    isOptionEqualToValue={(option, value) => option.name === value.name}
                                                    onChange={(event, newValue) => handleChangeUser(newValue)}
                                                    noOptionsText={"Nenhum Usuário com esse nome foi encontrando"}
                                                    renderInput={(params) => (
                                                        <TextField
                                                        {...params}
                                                        label="Escolher vendedor"
                                                        />
                                                    )}
                                                />
                                            </Col>
                                        </Row>
                                        <Row gutter={[16, 16]} style={{ marginTop: 24 }}>
                                            <Col span={8} > 
                                                <TextField 
                                                    label="Campanha"
                                                    fullWidth
                                                    id="campanha"
                                                    size="small"
                                                    value={campanha && campanha}
                                                    onChange={(e)=> {
                                                        setCampanha(e.target.value);
                                                    }} 
                                                />
                                            </Col>
                                            <Col span={8}>
                                                <Autocomplete
                                                    size="small"
                                                    id="combo-box-demo"
                                                    value={listaIDCustomer && listaIDCustomer}
                                                    sx={{ width: '100%' }}
                                                    getOptionLabel={(listCustomer) => `${listCustomer.name ? listCustomer.name : "Escolha o cliente"}`}
                                                    options={listCustomer}
                                                    isOptionEqualToValue={(option, value) => option.name === value.name}
                                                    onChange={(event, newValue) => {
                                                        handleChangeClient(newValue)
                                                    }}
                                                    noOptionsText={"Nenhum Cliente com esse nome foi encontrando"}
                                                    renderInput={(params) => (
                                                        <TextField
                                                        {...params}
                                                        size="small"
                                                        placeholder="Escolher cliente"
                                                        />
                                                    )}
                                                />
                                            </Col>
                                            <Col span={8}>
                                                <SelectedTag 
                                                    selectedPanels={panel} 
                                                    onChange={handleChangePanel}
                                                    data={listPanel} />
                                            </Col>
                                        </Row>
                                        <Row gutter={[16, 16]} style={{ marginTop: 24 }}>
                                            <Col span={6} >
                                                <DateRangePicker
                                                    handleChange={handleChangeDays}
                                                    size="large"
                                                    input={[dayjs(dtIni, 'YYYY-MM-DD'), dayjs(dtFim, 'YYYY-MM-DD')]}
                                                    />
                                            </Col>
                                            <Col span={6} >

                                                <Autocomplete 
                                                    disablePortal 
                                                    size='small' 
                                                    id="combo-box-demo" 
                                                    name="service" 
                                                    value={listaIDService || null}
                                                    options={listService}
                                                    sx={{ width: '100%' }}
                                                    getOptionLabel={(listService) => `${listService.name ? listService.name : "Escolha o cliente"}`}
                                                    isOptionEqualToValue={(option, value) => option.name === value.name}
                                                    onChange={(event, newValue) => {
                                                        handleChangeService(newValue);
                                                    }}
                                                    noOptionsText={"Nenhum inserção cadastrada no momento com esse valor"}
                                                    renderInput={(params) => (
                                                        <TextField
                                                        {...params}
                                                        size="small"
                                                        label="Serviço"
                                                        />
                                                    )}
                                                />

                                            </Col>
                                            <Col span={6} >
                                                <Autocomplete 
                                                    disablePortal 
                                                    size='small' 
                                                    id="combo-box-demo" 
                                                    name="qtdInsert" 
                                                    value={qtdInsercao || null}
                                                    options={listInserts}
                                                    sx={{ width: '100%' }}
                                                    getOptionLabel={(listInserts) => listInserts ? `${listInserts}` : ""}
                                                    isOptionEqualToValue={(option, value) => option === value}
                                                    onChange={(event, newValue) => {
                                                        handleChangeQtdService(0, newValue);
                                                    }}
                                                    noOptionsText={"Nenhum inserção cadastrada no momento com esse valor"}
                                                    renderInput={(params) => (
                                                        <TextField
                                                        {...params}
                                                        size="small"
                                                        label="Qtd inserção"
                                                        />
                                                    )}
                                                />
                                            </Col>
                                            <Col span={6} >
                                                <Button type="primary" size="large" onClick={handleFind}>Pesquisar</Button>
                                            </Col>
                                        </Row>
                                        
                                        <Row gutter={16} style={{ marginTop: 24 }}>
                                            {
                                                loading ? <Skeleton /> : 
                                                panelFields.length > 0 ?
                                                    panelFields.map((item, index) => {
                                                        if(item.panel.idpanel){
                                                            return(
                                                                <>
                                                                    <Col key={item.panel.idpanel}>
                                                                        <ImgMediaCard 
                                                                            item={item} 
                                                                            isChecked={item.isChecked} 
                                                                            days={days} 
                                                                            vlService={totalService} 
                                                                            service={servicePanel}
                                                                            onChange={(e) => {onChangeCheckbox(e, index, item)}}
                                                                            handleChangeService={(e) => {handleChangeServicePanel(e, index)}}
                                                                            handleChangeQtdInsert={(e) => {handleChangeServiceQtdInsert(e, index)}}
                                                                            qtdInsercao={qtdInsercao}
                                                                            />
                                                                    </Col>
                                                                </>
                                                            )
                                                        }
                                                    })
                                                :
                                                <Text>Ainda não há resultado de paineis</Text>
                                            }
                                        </Row> 

                                    </div>
                                    </Content>
                                    <Footer
                                        style={{
                                            textAlign: 'center',
                                            marginTop: 24 
                                        }}
                                        >
                                        
                                        <Row gutter={[16, 16]}>
                                            <Col span={3} > 
                                                <Radio.Group
                                                    value={typeCalc}
                                                    onChange={(e) => {
                                                        setTypeCalc(e.target.value);
                                                        totalComDesconto(desconto, e.target.value);
                                                    }}
                                                    buttonStyle="solid">

                                                    <Radio.Button value="percent">%</Radio.Button>
                                                    <Radio.Button value="value">R$</Radio.Button>
                                                    
                                                </Radio.Group>
                                            </Col>
                                            <Col span={4} > 
                                                {
                                                    typeCalc == 'value'? 
                                                    <MyComponent  
                                                        handleInput={(e)=> {
                                                            setDesconto(e);
                                                            totalComDesconto(e, typeCalc);
                                                        }}
                                                        value={desconto && desconto}
                                                        labelInput="Desconto"/>
                                                    :
                                                    <Input
                                                        label="Desconto"
                                                        id="Desconto"
                                                        title="0,00"
                                                        size="small"
                                                        value={desconto && desconto}
                                                        onChange={(e)=> {
                                                            setDesconto(e.target.value);
                                                            totalComDesconto(e.target.value, typeCalc);
                                                        }} 
                                                        InputProps={{ 
                                                            startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                                        }}
                                                    />
                                                }
                                            </Col>
                                            <Col span={4} >
                                                
                                            </Col>
                                            <Col span={13} style={{ display: 'flex', alignItems: 'center' }}>
                                                <Text>Itens: </Text>
                                                <Text className="highlighted-number" style={{ marginLeft: 8 }}>
                                                    {
                                                        itensDaVenda.toLocaleString('pt-BR', config)
                                                    } 
                                                </Text>
                                                <Text style={{ marginLeft: 16 }}>Desconto: </Text>
                                                <Text className="highlighted-number" style={{ marginLeft: 8 }}>
                                                    {
                                                    typeCalc == 'value' ? 
                                                        desconto.toLocaleString('pt-BR', config)
                                                        :
                                                        desconto.toLocaleString('pt-BR', config) + '%'
                                                    } 
                                                </Text>
                                                <Text style={{ marginLeft: 16 }}>Total: </Text>
                                                <Text className="highlighted-number" style={{ marginLeft: 8 }}>
                                                    {total.toLocaleString('pt-BR', config)} 
                                                </Text>
                                            </Col>
                                        </Row>

                                        <Row style={{ marginTop: 24 }}>
                                            <Col span={24} style={{ display: 'flex', alignItems: 'right' }}>

                                                <Text>Total de Painéis: </Text>
                                                <Text className="highlighted-totals-blue" style={{ marginLeft: 8 }}>
                                                    {panelFields.filter((item) => item.idpanel > 0).length}    
                                                </Text>
                                                <Text style={{ marginLeft: 16 }}>Itens Selecionados: </Text>
                                                <Text className="highlighted-totals-blue" style={{ marginLeft: 8 }}>
                                                    {panelFields.filter((item) => item.isChecked == true).length}
                                                </Text>
                                                <Text style={{ marginLeft: 16 }}>Painéis Indisponíveis: </Text>
                                                <Text className="highlighted-totals-red" style={{ marginLeft: 8 }}>
                                                    {panelFields.filter((item) => item.idpanel > 0 && item.isActive == false).length}
                                                </Text>

                                            </Col>
                                        </Row>
                                    </Footer>
                                    <Footer style={{background:'#FFF'}}>
                                        <Row style={{ marginTop: 24 }}>
                                                
                                                <Col span={14} style={{ display: 'flex', alignItems: 'right' }}>
                                                </Col>
                                                <Col span={4} style={{ display: 'flex', alignItems: 'right' }}>
                                                
                                                        <Tooltip title="Criar venda">

                                                            <Button
                                                                onClick={preperData} 
                                                                type="primary" 
                                                                icon={<PlusOutlined />}
                                                                size="middle"
                                                                >
                                                                Criar venda
                                                            </Button>

                                                        </Tooltip>
                                                </Col>
                                                <Col span={4} style={{ display: 'flex', alignItems: 'right' }}>            
                                                        <Tooltip title="Criar Orçamento">
                                                            {
                                                                idbudget ? 
                                                                <Button
                                                                    onClick={editarBuget} 
                                                                    type="primary" 
                                                                    icon={<PlusOutlined />}
                                                                    size="middle"
                                                                    >
                                                                    Salvar
                                                                </Button>
                                                            : 
                                                                <Button
                                                                    onClick={createBuget} 
                                                                    type="primary" 
                                                                    icon={<PlusOutlined />}
                                                                    size="middle"
                                                                    >
                                                                    Salvar
                                                                </Button>
                                                                
                                                            }
                                                        </Tooltip>
                                                </Col>
                                            </Row>
                                    </Footer>
                                </Layout>
                            </Layout>
                </div>
                </div>
            </div>
    </Container>

    </>

    )

}
export default NewAvailability;