import moment from "moment";
import { apiClient } from "./api";


const availabilityService = {

    async findPanels(data, dtIni, dtFim, qtdInsercao){
        
        var dados = {
            id: data,
            dtStart: dtIni,
            dtEnd: dtFim,
            vlInsert: qtdInsercao 
        }

        console.log(dados);

        var retorno = await apiClient.post('/availability/findid', dados);

        return retorno.data;
    },

    async findItemsByPanel(data, dtIni, dtFim, qtdInsercao){
        
        var dados = {
            id: data,
            dtStart: dtIni,
            dtEnd: dtFim,
            vlInsert: qtdInsercao 
        }
        
        console.log(dados)

        var retorno = await apiClient.post('/availability/findPanelName', dados);

        return retorno.data;
    },

    async findBudget(idbudget){


        var retorno = await apiClient.get('/tasks/'+idbudget);

        return retorno.data;
    },
    
    

    async createBuget(data){
        
        console.log(data)

        var retorno = await apiClient.post("/budget", data);

        return retorno;

    },

    async editarBuget(data){
        
        console.log(data)

        var retorno = await apiClient.put("/budget", data);
        
        return retorno;

    },


    async findAll(data){

        const arrayData = [] = [];

        var date1 = new Date(data.dtini);
        var date2 = new Date(data.dtFim);

        var timeDiff = Math.abs(date2.getTime() - date1.getTime());
        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

       for (let index = 1; index <= diffDays +1; index++) {

            // const stringdate = '2023-01-01T00:00:00-03:00';
            const data1 = new Date(data.dtini)
            data1.setDate(data1.getDate() + index)
            
            let datess = moment(data1).format('YYYY-MM-DD')
            // console.log(datess)
            arrayData.push(datess);
        
       }

       var panelData = [];
       data.panel.forEach(item => {

            panelData.push({
                idpanel: item,
                dates: arrayData
            })
                
            
       });

        // console.log(panelData)
        var retorno = await apiClient.post('/availability', panelData);
        // console.log(retorno)
        return retorno.data;
    }

}

export default availabilityService;