import React, { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import SimpleBackdrop from '../../component/loading/loading';
import ResponsiveDialog from '../../component/dialog/dialog';
import useAuth from '../../hooks/useAuth';
import logoImage from '../../assets/img/nova-logo-branca.png';
import styled from 'styled-components';
import { Tag } from 'antd';
import packageJson from '../../../package.json';


const theme = createTheme();

const Card = styled.div`
    display: flex;
    background-color: #f7f7f7;
    box-shadow: 0 0 60px rgba(0, 0, 0, 0.1);
    border-radius: 14px;
    max-width: 900px; /* Largura máxima do card */
    margin: 0 auto; /* Centralizar o card na tela */
    overflow: hidden; /* Para evitar que o conteúdo ultrapasse as bordas */
    margin-top: 150px;
    text-align: left;
`;
 
const LeftSide = styled.div`
  flex: 1;
  background-color: #1F1F1F;
  color: #fff;
  padding: 10px;
  text-align: center;
`;

const RightSide = styled.div`
  flex: 1.5;
  padding: 90px;

`;

const Title = styled.h1`
  font-size: 30px;
  margin-top: 50px;
  padding: 30px;
`;

const TitleRight = styled.h1`
  font-size: 30px;
  padding: 10px;
`;

const Subtitle = styled.p`
  font-size: 14px;
  margin-bottom: 10px;
`;

const Logo = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
`;

const Label = styled.label`
  font-size: 14px;
  margin-bottom: 8px;
  align-items: left;
  width: 100% 
`;

const Input = styled.input`
  width: 100%;
  margin: 5px 0;
  padding: 10px;
  border: 0px;
  border-radius: 10px;
`;




export default function SignIn() {


  const [open, setOpen] = useState(false)
  const [messageDialog, setMessageDialog] = useState(false)
  const [titleDialog, setTitleDialog] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)


  let navigate = useNavigate();
  const { signin } = useAuth();


  const handleSubmit = (event) => {
    event.preventDefault();
    setOpen(true)

    const data = new FormData(event.currentTarget);

    var user = {
      login: data.get('email'),
      password: data.get('password')
    }

    signin(user).then(item => {

      localStorage.setItem("user_token", JSON.stringify(item.data));

      setOpen(false)
      navigate("/");
    })
    .catch(error => {
      handleClose()
      handleDialogOpen()
      setTitleDialog("Ops")
      setMessageDialog("Usuário ou senha incorreta")
    });

  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };


  return (
    <ThemeProvider theme={theme}>
      <Container component="main" style={{padding: 60}}>

      <SimpleBackdrop open={open} 
        handleClose={handleClose} 
        title={"Realizando login..."}
        />

      <ResponsiveDialog 
        open={openDialog} 
        handleClose={handleDialogClose}
        title={titleDialog}
        message={messageDialog} 
        />


        <Card>
                <LeftSide>
                    <Title><br/>Bem-vindo de volta!</Title>
                    <Subtitle>Acesse sua conta agora mesmo</Subtitle>
                    <Logo src={logoImage} width={"200"} height={"40"} alt="Logo" />
                    <Tag color={packageJson.ambiente == 'Homologação' ? "orange" : '#000'}>
                      {packageJson.ambiente}: {packageJson.version}
                    </Tag>
                </LeftSide>
                <RightSide>
                  <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <TitleRight>Login</TitleRight>
                        <Label style={{textAlign: 'left'}}>Informe seu login</Label>
                        <Input 
                            type="text" 
                            name='email' 
                            required={true}
                            placeholder="Digite seu login"
                            // onChange={(e)=> setEmail(e.target.value)} 
                        />
                        <Label style={{textAlign: 'left'}}>Informe sua senha</Label>
                        <Input 
                            type="password" 
                            name='password' 
                            required={true}
                            placeholder="Digite sua senha"
                            />

                    <Button
                        style={{
                              backgroundColor: 'red',
                              color: 'white',
                              padding: '10px',
                              border: 'none',
                              cursor: 'pointer',
                              width: '100%',
                              paddingTop: '10px',
                              marginTop: '20px',                              
                              borderRadius: '10px'
                              
                        }}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className=""
                    >
                        Acessar
                    </Button>
                  </Box>
                </RightSide>

               
            </Card>

      {/* <Login /> */}

        {/* <Card style={{height: 600, width: 800, alignContent: "center"}}>

          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Login
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Seu e-mail"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Senha"
                type="password"
                id="password"
                autoComplete="current-password"
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Logar
              </Button>
              <Grid container>
                <Grid item xs>
                </Grid>
                <Grid item>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />

        </Card> */}
      </Container>
    </ThemeProvider>
  );
}