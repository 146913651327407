import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import { CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal, Button, Typography } from 'antd';


const { Title, Paragraph } = Typography;


export default function DialogConfirm({open, handleClose, handleOk, title, message, status}) {

  const isMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <Modal
      centered
      visible={open}
      onCancel={handleClose}
      footer={[
        <Button key="no" onClick={handleClose}>
          NÃO
        </Button>,
        <Button style={{background: '#000', color: '#fff'}} key="yes"  onClick={handleOk}>
          SIM
        </Button>,
      ]}
      width={isMobile ? '100%' : 500}
    >
      <div style={{ textAlign: 'center', padding: 20 }}>
        {status === 'success' ? (
          <CheckCircleOutlined style={{ fontSize: 100, color: '#52c41a' }} />
        ) : status === 'error' ? (
          <CloseCircleOutlined style={{ fontSize: 100, color: '#ff4d4f' }} />
        ) : (
          <ExclamationCircleOutlined style={{ fontSize: 100, color: '#faad14' }} />
        )}
        {/* Adicione verificações para garantir que o título e a mensagem sejam renderizados */}
        {title && <Title level={4} style={{ marginTop: 20 }}>{title}</Title>}
        {message && <Paragraph style={{ color: '#6F89A4' }}>{message}</Paragraph>}
      </div>
    </Modal>
  );
};


  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  
  // return (
  //   <div>

  //     <Dialog
  //       maxWidth={"xs"}
  //       fullWidth={true}        
  //       fullScreen={fullScreen}
  //       open={open}
  //       onClose={handleClose}
  //       aria-labelledby="responsive-dialog-title"
  //       aria-describedby='resposive-dialog-describe'
  //     >

  //       <DialogContent align={"center"} style={{marginRight: 30, marginBottom: 30, marginLeft: 30, alignItems: "center"}}>
  //           <DialogTitle id="responsive-dialog-title"  >
  //               <p>
  //                 {
  //                   status == 'success' ? 
  //                     <CheckCircleOutlineIcon color='success' style={{fontSize: 100}}/>
  //                   : status == 'error' ? 
  //                     <HighlightOffOutlinedIcon color='error' style={{fontSize: 100}} />
  //                       : <WarningAmberIcon color='warning' style={{fontSize: 100}} />
  //                 }
  //               </p>
  //           <h5>{title}</h5>
  //           </DialogTitle>

  //           <DialogContentText id="alert" align="center" style={{color: "#6F89A4"}} className="mt-2" >
  //               {message}
  //           </DialogContentText>
  //       </DialogContent>
        
  //       <DialogActions>
  //         <Button autoFocus onClick={handleClose}>
  //           NÃO
  //         </Button>
  //         <Button onClick={handleOk} autoFocus>
  //           SIM
  //         </Button>
  //       </DialogActions>

  //     </Dialog>

  //   </div>
  // );
// }