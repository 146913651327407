// Task.js
import React, { useState } from 'react';
import { useDrag } from 'react-dnd';
import SmallCard from '../card-funil/card-funil-small/card-smal';
import { Modal } from 'antd';
import NewAvailability from '../../view/availability/insert-availability';
import ResponsiveDialog from '../dialog/dialog';
import { useNavigate } from 'react-router-dom';


const Task = ({ id, title, sourceColumnId, task, onDelete, onCloseSalve }) => {
  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = useState();
  const [messageDialog, setMessageDialog] = useState();
  const [titleDialog, setTitleDialog] = useState();
  const [statusDialog, setStatusDialog] = useState();

  const [isModalVisible, setIsModalVisible] = useState(false);

  // Configura o comportamento de drag da tarefa
    const [{ isDragging }, drag] = useDrag({
      type: 'task',
      item: { id, sourceColumnId },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    // Se `id` ou `title` não existirem, retorna nulo
    if (!id || !title) {
      return null;
    }

    const handleCardClick = () => {
      setIsModalVisible(true); // Exibe o modal
    };
  
    const handleOk = () => {
      setIsModalVisible(false); // Fecha o modal ao clicar em "OK"
    };
  
    const onClose = () => {
      setIsModalVisible(false); // Fecha o modal ao clicar em "Salvar"
      onCloseSalve()
      // navigate(0);
      // handleReload(true)
    };

    const handleCancel = () => {

      setIsModalVisible(false); // Fecha o modal ao clicar em "Cancelar"
      // navigate(0);
      // handleReload(true)
    };

    const handleDialogClose = () => {

      setOpenDialog(false);
    };

  return (

    <div
      ref={drag}
      style={{
        padding: 5,
        margin: '8px 0',
        overflow: 'visible'
      }}
    >

      <ResponsiveDialog 
        open={openDialog} 
        handleClose={handleDialogClose}
        title={titleDialog}
        message={messageDialog} 
        status={statusDialog}
      />

      <SmallCard 
        title={title}  
        subtitle={task.customer.name ? task.customer.name : "Orçamento avulso"}
        panelCount={task.items.length} //pegar os paineis
        value={task.ticket} 
        onClick={handleCardClick} 
        onDelete={(e) => {onDelete(id)}}
      />

      <Modal
        title={title}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null} // Remove o rodapé
        centered // Centraliza o modal na tela
        style={{ top: '5vh' }} // Centraliza verticalmente com base na viewport
        bodyStyle={{ 
          height: '80vh', // Define a altura como 90% da altura da viewport
          padding: 0, 
          margin: 0, 
          overflow: 'auto' // Permite rolagem se o conteúdo ultrapassar o modal
        }}
        width="90vw" // Define a largura como 90% da largura da viewport
        closable={true} // Remove o botão de fechar
        maskStyle={{ 
          backgroundColor: 'rgba(0, 0, 0, 0.5)' // Altera a opacidade do fundo
        }}
      >
        <div>
          <NewAvailability idbudget={id} onClose={onClose}/>
        </div>
      </Modal>
      
    </div>
  );
};

export default Task;
