import React from 'react';
import './SmallCard.css'; // Importa os estilos
import ConnectedTvOutlinedIcon from '@mui/icons-material/ConnectedTvOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import CloseIcon from '@mui/icons-material/Close';


const SmallCard = ({ title, subtitle, panelCount, value, body, onClick, onDelete }) => {

  const config = true ? {style: 'currency', currency: 'BRL'} : {minimumFractionDigits: 2};

  return (


    <div className="small-card" onClick={onClick}>
    {/* Botão de Exclusão */}
    <div className="small-card-close" onClick={(e) => {
      e.stopPropagation(); // Impede que o clique feche o card
      onDelete(); // Chama a função de exclusão
    }}>
      <CloseIcon style={{ cursor: 'pointer', color: '#ff5a5f' }} />
    </div>
  
    {/* Conteúdo do Card */}
    <div className="small-card-content">
      <div className="small-card-header">
        <div className="small-card-title">{title}</div>
        <div className="small-card-subtitle">{subtitle}</div>
      </div>
   
      <div className="small-card-body">
        <div className="small-card-body-content">
          {body}
        </div>
        <div className="small-card-value">
          <PaidOutlinedIcon style={{ width: '22px', paddingRight: '4px', color: '#ff9500' }} />
          {parseFloat(value).toLocaleString('pt-BR', config)} | {panelCount}
          <ConnectedTvOutlinedIcon style={{ width: '20px', paddingLeft: '4px', color: '#007aff' }} />
        </div>
      </div>
    </div>
  </div>

  );
};

export default SmallCard;
