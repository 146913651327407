import { Card, Typography, Popover } from 'antd';
import { CalendarOutlined } from '@ant-design/icons'; // Ícone de disponibilidade
import * as React from 'react';
import { useEffect, useState } from 'react';
import serviceService from '../../service/serviceService';
import './card.css';
import { Autocomplete, List, TextField } from '@mui/material';
import MenuDropdown from '../dropdown/dropdown';

export default function ImgMediaCard({
  item,
  days,
  vlService,
  service,
  handleChangeService,
  handleChangeQtdInsert,
  qtdInsercao,
}) {

  const { Text, Title } = Typography;
  const [listService, setListService] = useState([]);
  const [listInserts, setListInserts] = useState([]);
  const [localService, setLocalService] = useState(item.service || service);
  const [localQtdInsercao, setLocalInsercao] = useState(item.qtdInsert || qtdInsercao);

  useEffect(() => {
    serviceService.listActive().then((items) => setListService(items));
    serviceService
      .listQtdInsert()
      .then((items) => setListInserts(items))
      .catch((error) => console.error(error));
  }, []);

  const handleLocalServiceChange = (event, newValue) => {
    setLocalService(newValue);
    handleChangeService(newValue, item.panel.idpanel);
  };

  const handleLocalInsercaoChange = (event, newValue) => {
    setLocalInsercao(newValue);
    handleChangeQtdInsert(newValue);
  };

  return (
    <Card
      hoverable
      style={{
        width: 200,
        height: 300,
        borderRadius: '12px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
        margin: '10px',
        backgroundColor: '#f7f7f7',
        position: 'relative',
      }}
    >
      {/* Título */}
      <Title level={5} style={{ marginBottom: '8px', textAlign: 'center', color: '#333' }}>
        {item.panel.name}
      </Title>

      {/* Descrição */}
      <div style={{ marginBottom: '16px', fontSize: '0.9rem', textAlign: 'center' }}>
        <Text style={{ color: '#555' }}>
          <strong>Qtd. Dias:</strong> {days}
        </Text>
      </div>

      {/* Serviço */}
      <div style={{ marginBottom: '8px', display: 'flex', justifyContent: 'center' }}>
        <Autocomplete
          disablePortal
          size="small"
          id="combo-box-service"
          value={localService}
          options={listService}
          getOptionLabel={(option) => option.name || 'Escolha o serviço'}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          onChange={handleLocalServiceChange}
          noOptionsText="Nenhum serviço cadastrado"
          renderInput={(params) => (
            <TextField {...params} size="small" label="Serviço" variant="outlined" />
          )}
          sx={{ width: '160px', fontSize: '0.8rem' }}
        />
      </div>

      {/* Quantidade de Inserções */}
      <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'center' }}>
        <Autocomplete
          disablePortal
          size="small"
          id="combo-box-qtdInsert"
          value={localQtdInsercao || null}
          options={listInserts}
          getOptionLabel={(option) => option || ''}
          isOptionEqualToValue={(option, value) => option === value}
          onChange={handleLocalInsercaoChange}
          noOptionsText="Nenhuma inserção disponível"
          renderInput={(params) => (
            <TextField {...params} size="small" label="Qtd Inserção" variant="outlined" />
          )}
          sx={{ width: '160px' }}
        />
      </div>

      {/* Dropdown de Availability e Valor */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '8px',
          padding: '0 0px',
        }}
      >

        <div
          style={{
            textAlign: 'center',
            cursor: 'pointer',
          }}
        >
          <MenuDropdown list={item.availability} />
        </div>

        {/* Valor */}
        <Text
          strong
          style={{
            fontSize: '1.1em',
            color: '#0073e6',
            textAlign: 'right',
          }}
        >
          {new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(item.vlTotal || vlService)}
        </Text>
      </div>
    </Card>

  );
}
 
